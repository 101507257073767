//import Swup from 'swup'
//import SwupScrollPlugin from '@swup/scroll-plugin'
//import SwupScriptsPlugin from '@swup/scripts-plugin'
//import SwupPreloadPlugin from '@swup/preload-plugin'
import 'lazysizes';

// require("./pageTransition");
// require("./navigation");
// require("./sticky");
// require("./lazy");
// require("./search");
// require("./productConnect");
// require('./shopFilter')
// require("./filter");
// require("./portal");
// require("./slider");
// require('./employmentVerification');

// Dynamically Import JS modules
const main = async () => {
  const navigation = (await import(/* webpackChunkName: 'navigation' */ './navigation')).default();
  const sticky = (await import(/* webpackChunkName: 'sticky' */ './sticky')).default();
  const search = (await import(/* webpackChunkName: 'search' */ './search')).default();
  // const productConnect = (await import(/* webpackChunkName: 'productConnect' */ './productConnect')).default();
  const filter = (await import(/* webpackChunkName: 'filter' */ './filter')).default();
  const portal = (await import(/* webpackChunkName: 'portal' */ './portal')).default();
  const slider = (await import(/* webpackChunkName: 'slider' */ './slider')).default();
  // const employmentVerification = (await import(/* webpackChunkName: 'employmentVerification' */ './employmentVerification')).default();
  const parallax = (await import(/* webpackChunkName: 'parallax' */ './parallax')).default();
}

main().then(() => {
  const options = {
    debugMode: false,
    plugins: [
      //new SwupScrollPlugin(),
      //new SwupScriptsPlugin(),
      //new SwupPreloadPlugin()
    ]
  }

  //const swup = new Swup(options)

  window.onload = () => {
    document.documentElement.classList.remove('is-animating')
  }

  // document.addEventListener('swup:contentReplaced', () => {
  //   // Load Inline Scripts
  //   let main = document.getElementById('swup')
  //   let scripts = [...main.querySelectorAll('script')]
  //   let badScriptCount = 0
  //   // let scripts = main.getElementsByTagName('script')
    
  //   scripts.forEach(script => {
  //     badScriptCount += 1
  //   })
  
  //   if (badScriptCount > 0) {
  //     window.location.reload()
  //   }
  // })
})